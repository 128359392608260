<template>
  <section class="flex__right pt-15">
    <download-excel
      :data="exportData"
      name="inventory-records.csv"
      type="csv"
      class="btn btn__sm btn__green"
    >Export</download-excel>
  </section>
</template>

<script>
import moment from "moment";
export default {
  props: ["data"],
  computed: {
    exportData() {
      const copyData = JSON.parse(JSON.stringify(this.data));
      const inventory = copyData.map(item => {
        return {
          _id: item._id,
          createdAt: moment(item.createdAt).format("MM-DD-YYYY hh:mma"),
          name: item.productName,
          inventory: item.currentInv,
          change: item.quantity,
          adjustment: item.adjustment ? "yes" : "no",
          itemCost: item.itemCost,
          invoiceCost: item.invoiceCost,
          receipt: item.receipt,
          comments: item.comments
        };
      });
      return inventory;
    }
  }
};
</script>

<style scoped lang="scss">
</style>