<template>
  <section class="inventory-header">
    <div class="container">
      <div class="flex__between">
        <router-link to="/admin" class="link__none breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
        </router-link>
        <router-link to="/admin/inventory-products" class="breadcrumb">
          Product Inv.
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </div>
      <h1 class="text__center padding__sm">Inventory Records</h1>
      <p class="header--message pre-wrap">
        Add and view product inventory by clicking on "Products" above.
        <br />
        <br />
        <i>If a particular Inventory-In record is incorrect, please complete an adjustment for that product.</i>
      </p>
      <FilterButton
        class="products-header--sort mt-15"
        @toggleFilters="emitToggleFilters"
        @clearFilters="clearFilters"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import FilterButton from "../../FilterButton";
export default {
  components: {
    FilterButton
  },
  methods: {
    ...mapActions(["startUnsetInventoryFilters"]),
    emitToggleFilters() {
      this.$emit("toggleFilters");
    },
    clearFilters() {
      this.startUnsetInventoryFilters();
    }
  }
};
</script>

<style scoped lang="scss">
@media (min-width: $md) {
  .products-header--sort {
    display: none;
  }
}
</style>