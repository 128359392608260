<template>
  <section class="inventory">
    <Header @toggleFilters="toggleFilters" />
    <div class="container">
      <Export :data="getFilteredInventory" />
      <div class="inventory-items__grid margin__md">
        <transition name="filter-slide">
          <Filters
            v-if="filtersToggled"
            @toggleFilters="toggleFilters"
            :filters="getInventoryFilters"
            class="small--filters"
          />
        </transition>
        <Filters :filters="getInventoryFilters" class="large--filters" />
        <InventoryIn :inventory="getFilteredInventory" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Filters from "./Filters";
import Export from "./Export";
import InventoryIn from "./InventoryIn";
export default {
  components: {
    Header,
    Filters,
    Export,
    InventoryIn
  },
  data() {
    return {
      filtersToggled: false
    };
  },
  computed: {
    ...mapGetters(["getFilteredInventory", "getInventoryFilters"])
  },
  methods: {
    toggleFilters() {
      this.filtersToggled = !this.filtersToggled;
    }
  }
};
</script>

<style scoped lang="scss">
@media (min-width: $md) {
  .inventory-items__grid {
    @include grid($cols: 1fr 3fr, $col-gap: 50px);
  }
}
</style>