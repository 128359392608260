<template>
  <div class="inventory-in--filters filters tile">
    <div class="flex__between">
      <h2>Filters</h2>
      <span class="breadcrumb" @click="clear">Reset</span>
    </div>
    <div class="flex__between m-tb-sm">
      <button class="btn btn__red" @click="emitToggleFilters">Exit</button>
      <button class="btn btn__frost" @click="submit">Filter</button>
    </div>
    <div class="inventory-in--filters__grid">
      <label>
        Product Name
        <input
          type="text"
          v-model="form.name"
          placeholder="product"
          @keypress.enter="submit"
        />
      </label>
      <label>
        Date Sort
        <select v-model="form.sort">
          <option value="newest-to-oldest">Newest to Oldest</option>
          <option value="oldest-to-newest">Oldest to Newest</option>
        </select>
      </label>
      <label>
        Date Start
        <input
          type="date"
          v-mask="'####-##-##'"
          placeholder="YYYY-MM-DD"
          v-model="form.dateStart"
        />
      </label>
      <label>
        Date End
        <input
          type="date"
          v-mask="'####-##-##'"
          placeholder="YYYY-MM-DD"
          v-model="form.dateEnd"
        />
      </label>
      <label>
        Is Adjustment
        <select v-model="form.adjustment">
          <option value>Any</option>
          <option :value="true">Yes</option>
          <option :value="false">No</option>
        </select>
      </label>
    </div>
  </div>
</template>
      name: "",
      dateStart: "",
      dateEnd: "",
      adjustment: "",
      sort: "newest-to-oldest"
<script>
import { mapActions } from "vuex";
export default {
  props: ["filters"],
  data() {
    return {
      form: {
        name: this.filters ? this.filters.name : null,
        dateStart: this.filters ? this.filters.dateStart : null,
        dateEnd: this.filters ? this.filters.dateEnd : null,
        sort: this.filters ? this.filters.sort : "newest-to-oldest",
        adjustment: this.filters ? this.filters.adjustment : null
      }
    };
  },
  watch: {
    filters: {
      handler: function(newVal) {
        this.form = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["startSetInventoryFilters", "startUnsetInventoryFilters"]),
    clear() {
      this.startUnsetInventoryFilters();
      this.emitToggleFilters();
    },
    submit() {
      this.startSetInventoryFilters(this.form);
      this.emitToggleFilters();
    },
    emitToggleFilters() {
      this.$emit("toggleFilters");
    }
  }
};
</script>

<style scoped lang="scss">
.inventory-in--filters__grid {
  @include grid($cols: 1fr, $col-gap: 10px, $row-gap: 5px);
}
.inventory-in--filters__grid label {
  @include grid($rows: 25px 1fr);
  font-weight: bold;
}
@media (min-width: $md) {
  .btn__red {
    display: none;
  }
}
</style>