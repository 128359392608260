<template>
  <section class="inventory-in">
    <p
      v-if="!inventory || inventory.length <= 0"
      class="text__bold text__center margin__md"
    >No results</p>
    <div class="inventory-in--list">
      <InventoryItem v-for="item in inventory" :key="item._id" :item="item" />
    </div>
  </section>
</template>

<script>
import InventoryItem from "./InventoryItem/Index";
export default {
  props: ["inventory"],
  components: {
    InventoryItem
  }
};
</script>

<style scoped lang="scss">
.inventory-in--list {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 25px, $align: center);
  height: fit-content;
}
@media (min-width: $lg) {
  .inventory-in--list {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>